type ButtonProps = {
    onClick: () => void;
    label: React.ReactNode;
    className?: string;
};

export const Button = (props: ButtonProps) => {
    return (
        <button
            data-testid={props.label}
            onClick={props.onClick}
            className={`rounded border-2 border-primary-500 bg-white px-3 py-1.5 text-xs font-bold text-primary-500 ${
                props.className ?? ""
            }`}
        >
            {props.label}
        </button>
    );
};
