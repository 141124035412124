import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import useBarcodeScanner from "./../hooks/useBarcodeScanner";
import { AgeVerificationDialog } from "./AgeVerificationDialog";
import { CartProduct, Product } from "./Product";
import { ProductAccessRow } from "./ProductAccessRow";
import { PromoBanner } from "./Promotion";
import kitchenMenuLogo from "../../../assets/kitchen-menu.svg";
import { Cart } from "../../types/Cart";
import { PromotionalProduct } from "../../types/Promotion";
import * as Price from "../../utils/Price";
import { useConfig } from "../ConfigProvider";
import { useOnScanProduct } from "../hooks/useOnScanProduct";
import { Payment } from "../Payment";
import { ProductNotFoundDialog } from "../ProductNotFound";
import { ProductPicker } from "../productPicker/ProductPicker";
import { SpinnerOverlay } from "../Spinner";
import { useRootState } from "../state/StateProvider";
import * as svg from "../svg";
import { useDelayedCondition } from "../useDelayedCondition";

const productsFromCart = (
    cart: Cart,
    promotionalProducts: PromotionalProduct[]
): CartProduct[] => {
    return cart.lineItems.map(
        ({
            product,
            linePrice,
            grossPrice,
            quantity,
            addOnOptions,
            isDeleted,
            id
        }) => ({
            id,
            name: product.name,
            quantity,
            addOnOptions,
            promotionalProduct: promotionalProducts.find(
                ({ code }) => code === product.barcode
            ),
            originalPrice: Price.format(grossPrice),
            finalPrice: Price.format(linePrice),
            barcode: product.barcode,
            isAgeRestricted: product.isAgeRestricted,
            isDeleted: isDeleted
        })
    );
};

const CartComponent = () => {
    const { state } = useRootState("scan");
    const isLoading = state.subState.kind === "loading";
    const { accessibilityMode } = state;

    const displayLoadingIndicator = useDelayedCondition(isLoading, 2000);
    const { promotionalProducts, kitchen } = useConfig();

    const { cart } = state;

    const products = productsFromCart(cart, promotionalProducts);

    const { t } = useTranslation();

    const [showPayment, setShowPayment] = useState(false);

    const totalPrice = Price.format(cart.totalPrice);

    const promos = (
        <div className="grid grid-cols-2 gap-2">
            {promotionalProducts.map((p) => (
                <PromoBanner key={p.code} promotionalProduct={p} />
            ))}
        </div>
    );

    const kitchenDescription = (
        <div className="flex h-full flex-col justify-center gap-10">
            <div className="flex flex-col items-center justify-center gap-4">
                <div className="text-primary-500">{svg.scan}</div>
                <div className="w-64 flex-wrap text-center font-semibold text-black">
                    {t("scan")}
                </div>
            </div>
            <hr className="mx-auto my-0 h-0.5 w-5/12" />
            <div className="flex flex-col items-center justify-center gap-4">
                <img src={kitchenMenuLogo} className="mx-auto h-14 w-auto" />
                <div className="w-64 flex-wrap text-center font-semibold text-black">
                    {t("kitchenDescription")}
                </div>
            </div>
        </div>
    );

    return (
        <>
            <div data-testid="cartComponent" className="flex h-full flex-col">
                {displayLoadingIndicator && <SpinnerOverlay />}
                <div
                    className={`flex flex-[1_0px] flex-col overflow-y-auto px-3  ${
                        accessibilityMode && "h-24"
                    }`}
                >
                    {products.length === 0 ? (
                        <>
                            {kitchen.hasKitchen ? (
                                kitchenDescription
                            ) : (
                                <div className="flex flex-auto flex-col items-center justify-center gap-10">
                                    <div className="text-primary-500">
                                        {svg.scan}
                                    </div>
                                    <div className="h-24 w-64 flex-wrap text-center font-semibold text-primary-800">
                                        {t("scan")}
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <AnimatePresence>
                            {products.map((product, index) => (
                                <Product
                                    key={product.name + "-" + index}
                                    product={product}
                                />
                            ))}
                        </AnimatePresence>
                    )}
                </div>

                <div
                    className={`
                        flex flex-col gap-3 border-primary-200 p-3
                        ${products.length > 0 ? "border-t-2" : ""}
                    `}
                >
                    {promotionalProducts.length > 0 && promos}

                    <div className="font-bold text-primary-800">
                        {t("total")}
                        <span className="float-right">{totalPrice}</span>
                    </div>

                    <button
                        disabled={Price.isZero(cart.totalPrice)}
                        data-testid="toPaymentButton"
                        onClick={() => setShowPayment(true)}
                        className="press-effect flex flex-col items-center rounded bg-primary-500 py-2 text-center leading-tight text-white disabled:bg-gray-300"
                    >
                        <span className="font-bold">{t("toPayment")}</span>
                        <span className="text-xs opacity-80">{totalPrice}</span>
                    </button>

                    <ProductAccessRow />
                </div>
            </div>

            <AgeVerificationDialog />
            {showPayment && <Payment onCancel={() => setShowPayment(false)} />}
        </>
    );
};

export const SelfCheckout = () => {
    const { state, dispatch } = useRootState("scan");

    const { cart, subState } = state;

    const onScan = useOnScanProduct();
    useBarcodeScanner({ onScan });

    return (
        <>
            {subState.kind === "error" && (
                <ProductNotFoundDialog
                    onOk={() => dispatch({ kind: "hideProductNotFound" })}
                />
            )}
            {cart && <CartComponent />}
            <ProductPicker />
        </>
    );
};
