import { AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";

import { KitchenMenuDialog } from "./KitchenMenuDialog";
import { KitchenProductSelectorDialog } from "./KitchenProductSelectorDialog";
import { SlideUp } from "../Animations";
import { useConfig } from "../ConfigProvider";
import useBarcodeScanner from "../hooks/useBarcodeScanner";
import { useOnScanProduct } from "../hooks/useOnScanProduct";
import { ProductNotFoundDialog } from "../ProductNotFound";
import { SpinnerOverlay } from "../Spinner";
import { useRootState } from "../state/StateProvider";
import * as svg from "../svg";
import { useDelayedCondition } from "../useDelayedCondition";

const ProductAddedToast = () => {
    const { t } = useTranslation();

    return (
        <div className="flex items-center gap-2 rounded-full bg-primary-500 px-3 py-1 text-sm font-semibold text-white shadow-sm">
            <div className="text-primary-500">{svg.solidCheckmark}</div>
            <div>{t("productAdded")}</div>
        </div>
    );
};

export const Kitchen = () => {
    const { state, dispatch } = useRootState("kitchen");
    const { accessibilityMode, subState } = state;

    const isLoading = state.subState.kind === "loading";
    const displayLoadingIndicator = useDelayedCondition(isLoading, 2000);

    const { kitchen } = useConfig();

    const onScan = useOnScanProduct();
    useBarcodeScanner({ onScan });

    return (
        <AnimatePresence>
            (
            <div data-theme="kitchen">
                <SlideUp
                    key="select"
                    className={`fixed inset-x-0 bottom-0 ${
                        accessibilityMode ? "top-80" : "top-0"
                    } z-10 rounded-t-xl`}
                >
                    {displayLoadingIndicator && <SpinnerOverlay />}
                    {subState.kind === "error" && (
                        <ProductNotFoundDialog
                            onOk={() =>
                                dispatch({ kind: "hideProductNotFound" })
                            }
                        />
                    )}
                    <KitchenMenuDialog
                        cartQuantity={state.cart.lineItems.length}
                        cartTotal={state.cart.totalPrice}
                        kitchenMenu={state.menu}
                        onCancel={() => dispatch({ kind: "hideKitchenMenu" })}
                    />
                    {state.subState.kind === "productSelector" && (
                        <KitchenProductSelectorDialog
                            product={state.subState.product}
                            upsellingProducts={
                                kitchen.upsellingConfiguration?.[
                                    state.subState.product.id
                                ] ?? []
                            }
                            onConfirm={(
                                productId,
                                barcode,
                                quantity,
                                addOnOptionIds,
                                upsellingProductBarcodes
                            ) => {
                                dispatch({
                                    kind: "showMessage",
                                    message: {
                                        content: <ProductAddedToast />,
                                        timeoutMs: 2500,
                                        type: "toast"
                                    }
                                });

                                dispatch({
                                    kind: "addKitchenProduct",
                                    productId,
                                    barcode: barcode,
                                    quantity,
                                    addOnOptionIds,
                                    upsellingProductBarcodes
                                });
                            }}
                            onCancel={() =>
                                dispatch({
                                    kind: "hideKitchenProductSelector"
                                })
                            }
                        />
                    )}
                </SlideUp>
            </div>
            )
        </AnimatePresence>
    );
};
