import { motion } from "framer-motion";
import { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { debounce } from "./debounce";
import { Feedback } from "./Feedback";
import { Receipt } from "./Receipt";
import { useRootState } from "./state/StateProvider";
import approveImage from "../../assets/approved.svg";

export const CheckoutSuccess = () => {
    const { t } = useTranslation();
    const { state, dispatch } = useRootState("checkoutSuccess");

    const [printed, setPrinted] = useState(false);

    const printReceipt = async () => {
        await dispatch({ kind: "printReceipt", orderId, kitchenOrderId });
        setPrinted(true);
    };

    const debouncedPrint = useMemo(
        () => debounce(printReceipt, 500, "immediate"),
        []
    );

    const { subState, orderId, userId, displayId, kitchenOrderId } = state;

    const showReceipt =
        subState.kind === "receiptLoading" ||
        subState.kind === "receiptSuccess";

    const GetReceiptButton = () => {
        return kitchenOrderId ? null : (
            <button
                data-testid="getReceipt"
                onClick={() => dispatch({ kind: "startGetReceipt", orderId })}
                className="press-effect m-5 cursor-pointer bg-white px-8 py-1.5 text-xs font-bold text-green-500"
            >
                {t("getReceipt")}
            </button>
        );
    };
    const PrintButton = () => {
        return printed ? (
            <div data-testid="receiptPrinted" className="text-white">
                {t("receiptPrinted")}
            </div>
        ) : (
            <button
                className="press-effect m-5 cursor-pointer bg-white px-8 py-1.5 text-xs font-bold text-green-500"
                onClick={debouncedPrint}
            >
                {t("printReceipt")}
            </button>
        );
    };
    const FinalizeButton = () => {
        return (
            <div className="mx-4">
                <button
                    data-testid="finalizeSession"
                    onClick={() => dispatch({ kind: "finalizeSession" })}
                    className="press-effect mb-4 w-full cursor-pointer place-self-center rounded border-2 border-primary-500 bg-primary-500 px-3 py-4 text-center font-bold text-white"
                >
                    {t("finalizeSession")}
                </button>
            </div>
        );
    };

    const finalize = (
        <>
            <>
                <div
                    data-testid="paymentSuccess"
                    className="my-2 text-3xl font-semibold text-white"
                >
                    {t("endTitle")}
                </div>
                <div className="mb-3 max-w-[30ch] text-center text-white">
                    {t("endSubtitle")}
                    {displayId && (
                        <>
                            <div className="mx-8 my-4 rounded-xl bg-white p-6 text-center text-3xl text-green-500">
                                <div className="text-2xl opacity-75">
                                    {t("orderNumber")}
                                </div>
                                <div className="text-4xl font-bold">
                                    #{displayId}
                                </div>
                            </div>
                            <Trans i18nKey="takeReceipt" />
                        </>
                    )}
                </div>

                {userId ? <PrintButton /> : <GetReceiptButton />}
            </>
        </>
    );

    return (
        <div className="flex h-full flex-col bg-primary-100">
            <div className="m-auto flex flex-col items-center">
                <div
                    className={`m-auto flex flex-col items-center rounded-modal ${
                        showReceipt ? "bg-white" : "bg-green-500"
                    } p-6`}
                >
                    <motion.img
                        className={`${showReceipt ? "hidden" : "visible"}`}
                        layout
                        src={approveImage}
                    />
                    {showReceipt ? <Receipt /> : finalize}
                </div>
                {userId ? (
                    <div className="my-4 max-w-[30ch] text-center font-thin">
                        <Trans i18nKey="needReceipt" />
                    </div>
                ) : (
                    ""
                )}
            </div>
            <FinalizeButton />
            <Feedback />
        </div>
    );
};
