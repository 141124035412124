import * as svg from "./svg";

export const ToastMessage = ({ message }: { message: string }) => {
    return (
        <div className="flex items-center gap-2 rounded-full bg-primary-500 px-3 py-1 text-sm font-semibold text-white shadow-sm">
            <div className="text-primary-500">{svg.solidCheckmark}</div>
            <div>{message}</div>
        </div>
    );
};
