import Lottie from "lottie-react";
import { QRCodeSVG } from "qrcode.react";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import avec from "./avec.json";
import avec_autonomous from "./avec_autonomous.json";
import kkiosk from "./kkiosk.json";
import oxxo from "./oxxo.json";
import kitchenMenuLogo from "../../../assets/kitchen-menu.svg";
import { CartTransferError } from "../../types/Cart";
import { Tenant } from "../../types/Config";
import { useConfig } from "../ConfigProvider";
import { Dialog } from "../Dialog";
import useBarcodeScanner, {
    requiresOperatorBadgeScan
} from "../hooks/useBarcodeScanner";
import { useRootState } from "../state/StateProvider";
import * as svg from "../svg";

const getAnimation = (tenant: Tenant) => {
    switch (tenant) {
        case "avec":
            return (
                <Lottie
                    data-testid="screensaver"
                    animationData={avec}
                    loop
                    autoplay
                    muted
                    className="fixed top-0 h-screen w-screen object-cover"
                />
            );
        case "kkiosk":
            return (
                <Lottie
                    data-testid="screensaver"
                    animationData={kkiosk}
                    loop
                    autoplay
                    muted
                    className="fixed top-0 h-screen w-screen object-cover"
                />
            );
        case "oxxo":
            return (
                <Lottie
                    data-testid="screensaver"
                    animationData={oxxo}
                    loop
                    autoplay
                    muted
                    className="fixed top-0 h-screen w-screen object-cover"
                />
            );
    }
};

const StaffedScreenSaver = () => {
    const { t } = useTranslation();
    const { state, dispatch } = useRootState("welcome");
    const { tenant, kitchen } = useConfig();

    const onStart = () => {
        dispatch({ kind: "start" });
    };

    const productImages = kitchen.menu?.categories.flatMap(({ products }) =>
        products.map((p) => p.imageUrl)
    );

    return (
        <div
            className="flex h-full flex-1 flex-col items-center justify-end pb-32"
            onClick={onStart}
        >
            {productImages?.map((image, i) => (
                <link key={i} rel="preload" as="image" href={image} />
            ))}
            {getAnimation(tenant)}
            <div className="z-10 flex flex-col items-start gap-3 rounded-cta bg-primary-100 bg-opacity-80 p-8 backdrop-blur-lg">
                <div className="text-start text-2xl font-semibold text-primary-800">
                    {t("welcome")}
                </div>
                <div className="max-w-[25ch]">{t("welcomeScan")}</div>

                <button
                    disabled={state.subState.kind === "loading"}
                    data-testid="welcome"
                    className="flex w-full justify-between gap-2 place-self-center rounded-cta bg-primary-500 px-7 py-3.5 text-xl font-bold text-white hover:bg-primary-800"
                >
                    <div>{t("startShopping")}</div>
                    <div>{svg.play}</div>
                </button>
                <div className="w-[45ch] rounded-sm bg-yellow-50 px-4 py-2 text-xs text-slate-900 text-opacity-75">
                    <Trans i18nKey="promotionsDontWork" />
                </div>
            </div>
        </div>
    );
};

const StartFailedDialog = ({ error }: { error: CartTransferError }) => {
    const { dispatch } = useRootState("welcome");
    const { t } = useTranslation();
    return (
        <Dialog
            image={svg.productNotFound}
            title={t([
                `cartTransferError.${error.case}`,
                "cartTransferError.default"
            ])}
            primaryButton={{
                title: t("tryAgain"),
                action: () => {
                    dispatch({ kind: "reset" });
                }
            }}
        />
    );
};

const DownloadAppDialog = ({ onClick }: { onClick: () => void }) => {
    const { t } = useTranslation();
    const { checkinCode } = useConfig();
    return (
        <Dialog
            className="w-96"
            image={
                <div className="rounded-xl border-8 border-primary-500 p-3">
                    <QRCodeSVG size={180} value={checkinCode} />
                </div>
            }
            title={
                <div className="flex flex-col gap-4 pt-6">
                    <div className="grid grid-cols-2 gap-1">
                        <div className="flex flex-col gap-2">
                            <div>
                                <span className="rounded-full bg-primary-500 px-2 py-1 text-xs text-white">
                                    1
                                </span>
                            </div>
                            <div className="text-sm text-black">
                                {t("downloadAppDialog.step1")}
                            </div>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div>
                                <span className="rounded-full bg-primary-500 px-2 py-1 text-xs text-white">
                                    2
                                </span>
                            </div>
                            <div className="text-sm text-black">
                                {t("downloadAppDialog.step2")}
                            </div>
                        </div>
                    </div>
                    <div className="text-sm font-light text-black">
                        {t("downloadAppDialog.subtitle")}
                    </div>
                </div>
            }
            onBackgroundClick={onClick}
            primaryButton={{
                title: t("back"),
                action: onClick
            }}
        />
    );
};

const AutonomousScreenSaver = () => {
    const { t } = useTranslation();
    const { tenant } = useConfig();
    const { state } = useRootState("welcome");

    const [showAppDownload, setShowAppDownload] = useState(false);

    const Icon = () => <span className="text-primary-500">{svg.scoIcon}</span>;

    return (
        <div className="flex h-full flex-col items-center justify-center gap-6">
            {state.subState.kind === "error" && (
                <StartFailedDialog error={state.subState.reason} />
            )}
            {showAppDownload && (
                <DownloadAppDialog onClick={() => setShowAppDownload(false)} />
            )}
            {getAnimation(tenant)}
            <div className="z-10 flex w-4/5 flex-col items-center gap-6 rounded-xl bg-primary-800 bg-opacity-80 px-6 py-6 backdrop-blur-lg">
                <Lottie
                    data-testid="screensaver"
                    animationData={avec_autonomous}
                    loop
                    autoplay
                    muted
                    className={state.accessibilityMode ? "w-64" : "w-80"}
                />

                <div className="flex flex-col items-center justify-center gap-4">
                    <div className="text-center text-3xl font-semibold text-white">
                        {t("unlockWithApp.title")}
                    </div>
                    <div className="mx-3 text-center text-lg font-light text-white">
                        <Trans
                            i18nKey="unlockWithApp.subtitle"
                            t={t}
                            components={{ icon: <Icon /> }}
                        />
                    </div>
                </div>
            </div>

            <button
                className="z-10 text-center text-white underline"
                onClick={() => setShowAppDownload(true)}
            >
                {t("unlockWithApp.noApp")}
            </button>
        </div>
    );
};

export const Screensaver = () => {
    const { state, dispatch } = useRootState("welcome");
    const { operationMode, storeId, kitchen } = useConfig();
    const { t } = useTranslation();

    useBarcodeScanner({
        onScan: (code) => {
            if (requiresOperatorBadgeScan(state)) {
                dispatch({
                    kind: "scanOperatorBadge",
                    scannedBadge: code
                });
            } else if (operationMode === "autonomous") {
                dispatch({
                    kind: "transfer",
                    scannedCode: code
                });
            } else {
                dispatch({ kind: "start", scannedCode: code });
            }
        }
    });

    // The SCO lock is temporarily disabled for the Freiestrasse store (17936)
    const skipAutonomousLock = storeId === "17936";

    const screenSaver =
        operationMode === "autonomous" && !skipAutonomousLock ? (
            <AutonomousScreenSaver />
        ) : (
            <StaffedScreenSaver />
        );

    const kitchenLogo = kitchen.hasKitchen ? (
        <div className="z-10 mb-6 flex flex-col items-center gap-2">
            <div className="font-semibold text-white opacity-50">
                {t("availableAtAvec")}
            </div>
            <img src={kitchenMenuLogo} className="mx-auto h-20 w-auto" />
        </div>
    ) : null;

    return (
        <>
            {screenSaver}
            {kitchenLogo}
        </>
    );
};
