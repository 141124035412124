import { RootState } from "./StateProvider";
import { Action } from "../../types/Action";
import { Wrap } from "../../types/Wrap";

const handleInvalidAction = (
    actualState: RootState,
    action: Action,
    expectedAction: Action["kind"]
) => {
    throw new Error(
        `Tried dispatching invalid action ${action.kind} from state ${actualState.kind}. Expected the following action: ${expectedAction}.`
    );
};

const handleInvalidState = (
    actualState: RootState,
    expectedState: RootState["kind"]
) => {
    throw new Error(
        `Tried rendering component from invalid state ${JSON.stringify(
            actualState
        )}.
        Expected the following state for this component to be rendered: ${expectedState}.`
    );
};

const handleInvalidSubState = <
    U extends RootState,
    T extends U["kind"],
    R extends U["subState"]["kind"]
>(
    actualState: U,
    expectedState: T,
    expectedSubState: R
) => {
    throw new Error(
        `Tried rendering component from invalid state ${JSON.stringify(
            actualState
        )}.
        Expected the following state and subState for this component to be rendered: ${expectedState} ${expectedSubState}`
    );
};

export type NewMessage = {
    content: React.ReactNode;
    timeoutMs: number;
    type: "toast";
};

export type Message = NewMessage & {
    id: string;
};

export type WrapRootState<T, R = object> = Wrap<T, R> & {
    accessibilityMode: boolean;
    messages: Message[];
};

type NarrowUnion<Union, Match> = Extract<Union, Match>;

// guard is a "generic" type guard that can be used both by `RootState` and `SubState`
const guard = <T extends { kind: unknown }, R extends T["kind"]>(
    subState: T,
    expected: R
): subState is NarrowUnion<T, { kind: R }> => subState?.kind === expected;

const extract = <U extends { kind: unknown }, T extends U["kind"]>(
    union: U,
    expectedKind: T
): NarrowUnion<U, { kind: T }> | null => {
    if (guard(union, expectedKind)) {
        return union;
    }

    return null;
};

/// extractState uses narrowing to return the expected union case from rootState.
const extractState = <T extends RootState["kind"]>(
    rootState: RootState,
    expectedState: T
): NarrowUnion<RootState, { kind: T }> | null =>
    extract(rootState, expectedState);

const extractSubState = <
    U extends RootState,
    T extends U["kind"],
    R extends U["subState"]["kind"]
>(
    rootState: U,
    expectedState: T,
    expectedSubstate: R
): NarrowUnion<U["subState"], { kind: R }> | null =>
    extract(rootState, expectedState) &&
    extract(rootState.subState, expectedSubstate);

export const requireAction = <T extends Action["kind"]>(
    state: RootState,
    action: Action,
    expectedAction: T
): NarrowUnion<Action, { kind: T }> => {
    return (
        extract(action, expectedAction) ??
        handleInvalidAction(state, action, expectedAction)
    );
};

export const requireState = <T extends RootState["kind"]>(
    rootState: RootState,
    expectedState: T
): NarrowUnion<RootState, { kind: T }> => {
    return (
        extractState(rootState, expectedState) ??
        handleInvalidState(rootState, expectedState)
    );
};

export const requireOneOfStates = <T extends RootState["kind"]>(
    rootState: RootState,
    ...expectedStates: T[]
): NarrowUnion<RootState, { kind: T }> => {
    const firstNarrowedState = expectedStates
        .map((expected) => extractState(rootState, expected))
        .filter((x) => x != null)
        .at(0);
    return (
        firstNarrowedState ?? handleInvalidState(rootState, expectedStates[0])
    );
};

export const requireSubState = <
    U extends RootState,
    T extends U["kind"],
    R extends U["subState"]["kind"]
>(
    rootState: U,
    expectedState: T,
    expectedSubState: R
): NarrowUnion<U["subState"], { kind: R }> =>
    extractSubState(rootState, expectedState, expectedSubState) ??
    handleInvalidSubState(rootState, expectedState, expectedSubState);
