import { EventNameString, getAnalytics, logEvent } from "firebase/analytics";

import { getEzioId } from "./utils/id";

type CustomEvents =
    | "toggle_accessibility_mode"
    | "session_start"
    | "select_kitchen_product"
    | "product_not_found"
    | "age_verification_start"
    | "age_requirement_not_met"
    | "id_scan_success"
    | "id_scan_failure"
    | "update_line_item"
    | "checkout_success"
    | "checkout_failure"
    | "feedback_sent"
    | "receipt_requested"
    | "receipt_printed"
    | "cart_transfer_success"
    | "cart_transfer_failure"
    | "flappy_found"
    | "apply_coupon"
    | "apply_coupon_failure";

type AllEvents = EventNameString | CustomEvents;

export class Tracker {
    private properties: {
        self_checkout_terminal_id?: string;
        cartId?: string;
        language?: string;
    } = {
        self_checkout_terminal_id: getEzioId()
    };

    public setTrackingProperties(props: typeof this.properties) {
        this.properties = { ...this.properties, ...props };
        return this;
    }

    public resetTrackingProperties() {
        this.properties = {};
    }

    public track(
        eventName: AllEvents,
        eventParams?: { [key: string]: string | boolean | number | undefined }
    ) {
        const analytics = getAnalytics();

        if (import.meta.env.DEV)
            console.table({ eventName, ...eventParams, ...this.properties });

        return logEvent(analytics, eventName as string, {
            ...eventParams,
            ...this.properties
        });
    }
}
