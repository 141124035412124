import { trace } from "firebase/performance";
import {
    createContext,
    PropsWithChildren,
    useContext,
    useMemo,
    useState
} from "react";
import { useTranslation } from "react-i18next";

import {
    requireOneOfStates,
    requireState,
    requireSubState,
    WrapRootState
} from "./Common";
import {
    loadMoreOrders,
    scanOperatorBadge,
    showHardware,
    showInsights,
    showOrders
} from "./Operator";
import {
    CartClient,
    getEventPayload,
    getQuantity
} from "../../clients/CartClient";
import { LogClient } from "../../clients/LogClient";
import { OrderClient } from "../../clients/OrderClient";
import { PostCheckoutClient } from "../../clients/PostCheckoutClient";
import { getPerformanceInstance } from "../../firebase";
import { useLamp } from "../../Lamp";
import { MrzParseError, mrzToDocumentId } from "../../mrzParse";
import { usePrinter } from "../../Printer/printer";
import { Tracker } from "../../track";
import { Action, ManuallyAddedProduct, ScannedCode } from "../../types/Action";
import {
    Cart,
    CartError,
    CheckoutError,
    CartTransferError
} from "../../types/Cart";
import { KitchenMenu, KitchenProduct } from "../../types/KitchenMenu";
import { Operator } from "../../types/Operator";
import { ProductPreset } from "../../types/ProductPreset";
import { Wrap } from "../../types/Wrap";
import { httpClient } from "../../utils/http";
import { getEzioId } from "../../utils/id";
import { getSimulatePaymentResponse } from "../../utils/simulate";
import { debounceWithId } from "../debounce";
import { isValidTransferCode } from "../hooks/useBarcodeScanner";
import { logMemoryUsage } from "../logMemoryUsage";
import * as svg from "../svg";

export const useRootState = <T extends RootState["kind"]>(expectedState: T) => {
    const { state, dispatch } = useContext(StateContext);

    return { state: requireState(state, expectedState), dispatch };
};

export const useRootAndSubState = <
    U extends RootState,
    T extends U["kind"],
    R extends U["subState"]["kind"]
>(
    expectedState: T,
    expectedSubState: R
) => {
    const { state, dispatch } = useContext(StateContext);

    return {
        state: requireSubState(state, expectedState, expectedSubState),
        dispatch
    };
};

export const useAnyState = () => {
    return useContext(StateContext);
};

export type Welcome = WrapRootState<
    "welcome",
    {
        subState:
            | Wrap<"error", { reason: CartTransferError }>
            | Wrap<"idle" | "loading" | "operatorVerification">;
    }
>;

export type AgeVerification = Wrap<
    "ageVerification",
    {
        status: "unconfirmed" | "success" | "error" | "underage" | "invalidId";
        attemptedAction: ManuallyAddedProduct | ScannedCode;
    }
>;

export type Scan = WrapRootState<
    "scan",
    {
        cart: Cart;
        simulatePaymentResponse?: "success" | "failure" | "aborted";
        subState:
            | Wrap<"idle" | "loading" | "checkingOut">
            | Wrap<"productPicker", { productPreset: ProductPreset }>
            | AgeVerification
            | Wrap<"checkoutError", { reason: CheckoutError }>
            | Wrap<"error", { reason: CartError }>;
    }
>;

export type Kitchen = WrapRootState<
    "kitchen",
    {
        cart: Cart;
        menu: KitchenMenu;
        subState:
            | Wrap<"idle" | "loading">
            | Wrap<"productSelector", { product: KitchenProduct }>
            | Wrap<"error", { reason: CartError }>;
    }
>;

export type CheckoutSuccess = WrapRootState<
    "checkoutSuccess",
    {
        orderId: string;
        userId?: string;
        displayId?: number;
        kitchenOrderId?: string;
        subState:
            | Wrap<"idle" | "receiptLoading">
            | Wrap<"receiptSuccess", { receiptUrl: string }>;
    }
>;

export type Flappy = WrapRootState<"flappy", { subState: Wrap<"playing"> }>;

export type Error = WrapRootState<"error", { subState: Wrap<""> }>;

export type RootState =
    | Welcome
    | Scan
    | Kitchen
    | CheckoutSuccess
    | Flappy
    | Operator
    | Error;

export type State = {
    dispatch: (action: Action) => Promise<void>;
    state: RootState;
};

const defaultRootState = {
    kind: "welcome" as const,
    subState: { kind: "idle" as const },
    accessibilityMode: false,
    messages: []
};

export const StateContext = createContext<State>({
    dispatch: (_: Action) => Promise.resolve(),
    state: defaultRootState
});

export const StateProvider = ({ children }: PropsWithChildren) => {
    const client = httpClient;

    const { i18n } = useTranslation();

    const [state, setState] = useState<RootState>(defaultRootState);

    const lamp = useLamp();
    const { setColor } = lamp;

    const printer = usePrinter();
    const { connected, connect, print } = printer;

    const tracker = useMemo(() => new Tracker(), []);

    const defaultLanguage = i18n.options.fallbackLng as string;

    const reset = async () => {
        setState(defaultRootState);

        tracker.resetTrackingProperties();
        await i18n.changeLanguage(defaultLanguage);
        await setColor("Green");
    };

    const accessibilityModeAvailable = !["flappy", "error", "kitchen"].includes(
        state.kind
    );

    const actionHandler = (client: typeof fetch) => async (action: Action) => {
        const cartClient = new CartClient(client);
        const orderClient = new OrderClient(client);
        const postCheckoutClient = new PostCheckoutClient(client, tracker);
        const logClient = new LogClient(client);

        switch (action.kind) {
            case "start": {
                requireState(state, "welcome");

                // Chromium-only feature that gives us memory usage insights.
                if (window.performance?.memory) {
                    logMemoryUsage(logClient, window.performance.memory);
                }

                if (state.subState.kind === "loading") {
                    await logClient.info(
                        "Tried starting while on loading state"
                    );
                    return;
                }

                setState({
                    ...state,
                    kind: "welcome",
                    subState: { kind: "loading" }
                });

                let cart: Cart = await cartClient.checkin();

                tracker.setTrackingProperties({
                    cartId: cart.id,
                    language: i18n.language
                });

                const newState: Scan = {
                    ...state,
                    kind: "scan" as const,
                    subState: { kind: "idle" as const },
                    cart,
                    simulatePaymentResponse: getSimulatePaymentResponse()
                };

                setState(newState);
                setColor("Brand");
                tracker.track("session_start");
                tracker.track("page_view", { title: "scan" });

                if (action.scannedCode) {
                    const eventPayload = {
                        barcode: action.scannedCode.code,
                        quantity: 1,
                        source: "scan",
                        device: action.scannedCode.device
                    };

                    try {
                        cart = await cartClient.add(action.scannedCode.code);
                        setState({ ...newState, cart });
                    } catch (e) {
                        const error = e as CartError;
                        let subState: Scan["subState"] | undefined;
                        switch (error.case) {
                            case "IdScanRequired":
                                setColor("Orange");
                                tracker.track(
                                    "age_verification_start",
                                    eventPayload
                                );
                                subState = {
                                    kind: "ageVerification",
                                    status: "unconfirmed",
                                    attemptedAction: action.scannedCode
                                };

                                break;
                            case "ProductNotFound":
                                tracker.track(
                                    "product_not_found",
                                    eventPayload
                                );
                                subState = {
                                    kind: "error",
                                    reason: error
                                };
                                break;
                        }

                        if (subState) {
                            setState({
                                ...newState,
                                subState: subState
                            });
                            return;
                        }

                        throw e;
                    }
                }
                return;
            }

            case "addProduct": {
                const scanState = requireOneOfStates(state, "scan", "kitchen");

                setState({
                    ...scanState,
                    subState: { kind: "loading" }
                });
                const eventPayload = getEventPayload(action);
                try {
                    const cart = await cartClient.add(
                        action.payload.code,
                        getQuantity(action)
                    );
                    setState({
                        ...scanState,
                        kind: "scan",
                        cart,
                        subState: { kind: "idle" }
                    });

                    tracker.track("add_to_cart", eventPayload);
                } catch (e) {
                    const error = e as CartError;
                    let subState;
                    switch (error.case) {
                        case "ProductNotFound":
                            tracker.track("product_not_found", eventPayload);
                            subState = {
                                kind: "error" as const,
                                reason: error
                            };
                            break;
                        case "IdScanRequired":
                            setColor("Orange");
                            tracker.track(
                                "age_verification_start",
                                eventPayload
                            );
                            subState = {
                                kind: "ageVerification" as const,
                                status: "unconfirmed" as const,
                                attemptedAction: action.payload
                            };

                            break;
                        case "AgeRequirementNotMet":
                            setColor("Brand");
                            tracker.track(
                                "age_requirement_not_met",
                                eventPayload
                            );
                            subState = {
                                kind: "ageVerification" as const,
                                status: "underage" as const,
                                attemptedAction: action.payload
                            };

                            break;
                    }

                    if (subState) {
                        setState({
                            ...scanState,
                            kind: "scan",
                            subState: subState
                        });
                        return;
                    }

                    throw e;
                }

                return;
            }

            case "scanId": {
                const scanState = requireState(state, "scan");

                setState({
                    ...scanState,
                    subState: { kind: "loading" }
                });

                try {
                    const identityDocument = mrzToDocumentId(
                        action.identityDocumentScan.code
                    );

                    await cartClient.scanId(identityDocument);

                    setColor("Brand");
                    tracker.track("id_scan_success", {
                        device: action.identityDocumentScan.device
                    });
                    setState({
                        ...scanState,
                        subState: {
                            kind: "ageVerification",
                            status: "success",
                            attemptedAction: action.attemptedAction
                        }
                    });
                } catch (e) {
                    if ((e as MrzParseError) === "UnrecognizedMrz") {
                        setState({
                            ...scanState,
                            subState: {
                                kind: "ageVerification",
                                status: "invalidId",
                                attemptedAction: action.attemptedAction
                            }
                        });
                    } else {
                        const ageRequirementNotMetError = e as CartError;

                        let status;

                        if (
                            ageRequirementNotMetError.case ===
                                "AgeRequirementNotMet" ||
                            ageRequirementNotMetError.case === "Underage"
                        ) {
                            status = "underage" as const;
                        } else {
                            status = "error" as const;
                        }
                        tracker.track("id_scan_failure", {
                            reason: status,
                            device: action.identityDocumentScan.device
                        });
                        setState({
                            ...scanState,
                            subState: {
                                kind: "ageVerification",
                                status: status,
                                attemptedAction: action.attemptedAction
                            }
                        });
                    }
                }

                return;
            }

            case "updateLineItem": {
                const scanState = requireState(state, "scan");

                const t = trace(getPerformanceInstance(), "updateLineItem");

                let cart: Cart = (() => {
                    console.log(
                        "Optimistically updating cart line item",
                        action.product.id,
                        "with new quantity",
                        action.product.quantity
                    );

                    const newLineItems = scanState.cart.lineItems.map(
                        (lineItem) =>
                            lineItem.id === action.product.id
                                ? {
                                      ...lineItem,
                                      quantity: action.product.quantity
                                  }
                                : lineItem
                    );

                    return { ...scanState.cart, lineItems: newLineItems };
                })();

                setState({
                    ...scanState,
                    cart,
                    subState: { kind: "idle" }
                });

                tracker.track("update_line_item", { ...action.product });

                debounceWithId(async () => {
                    setState({
                        ...scanState,
                        cart,
                        subState: { kind: "loading" }
                    });

                    t.start();
                    cart = await cartClient.updateLineItem(
                        action.product.id,
                        action.product.quantity
                    );
                    t.stop();

                    setState({
                        ...scanState,
                        cart,
                        subState: { kind: "idle" }
                    });
                }, action.product.id);

                return;
            }

            case "deleteLineItem": {
                const scanState = requireState(state, "scan");

                setState({
                    ...scanState,
                    subState: { kind: "loading" }
                });
                const cart = await cartClient.deleteLineItem(action.product.id);
                setState({
                    ...scanState,
                    cart,
                    subState: { kind: "idle" }
                });
                tracker.track("remove_from_cart", { ...action.product });
                return;
            }

            case "showKitchenProductSelector": {
                const kitchenState = requireState(state, "kitchen");

                setState({
                    ...kitchenState,
                    subState: {
                        kind: "productSelector",
                        product: action.product
                    }
                });

                tracker.track("select_kitchen_product", {
                    productBarcode: action.product.barcode
                });

                return;
            }

            case "hideKitchenProductSelector": {
                const kitchenState = requireState(state, "kitchen");

                setState({
                    ...kitchenState,
                    subState: {
                        kind: "idle"
                    }
                });

                return;
            }

            case "addKitchenProduct": {
                requireState(state, "kitchen");

                setState((prev) => ({
                    ...(prev as Kitchen),
                    subState: { kind: "loading" }
                }));

                const eventPayload = {
                    productId: action.productId,
                    barcode: action.barcode,
                    quantity: action.quantity,
                    upsellingProductBarcodes:
                        action.upsellingProductBarcodes.join(",")
                };

                try {
                    const cart = await cartClient.add(
                        action.barcode,
                        action.quantity,
                        action.addOnOptionIds,
                        action.upsellingProductBarcodes
                    );

                    setState((prev) => ({
                        ...(prev as Kitchen),
                        subState: { kind: "idle" },
                        cart
                    }));

                    tracker.track("add_to_cart", eventPayload);
                } catch (e) {
                    const error = e as CartError;

                    if (error.case === "ProductNotFound") {
                        tracker.track("product_not_found", eventPayload);

                        setState((prev) => ({
                            ...(prev as Kitchen),
                            subState: {
                                kind: "error" as const,
                                reason: error
                            }
                        }));

                        return;
                    }

                    throw e;
                }

                return;
            }

            case "showOperatorBadgeVerification": {
                requireState(state, "welcome");

                setColor("Yellow");
                setState({
                    ...state,
                    kind: "welcome",
                    subState: {
                        kind: "operatorVerification"
                    }
                });
                return;
            }

            case "hideOperatorBadgeVerification": {
                requireState(state, "welcome");

                setColor("Green");
                setState({
                    ...state,
                    kind: "welcome",
                    subState: {
                        kind: "idle"
                    }
                });
                return;
            }

            case "scanOperatorBadge":
                return setState(
                    await scanOperatorBadge(
                        state,
                        action,
                        client,
                        i18n.language,
                        lamp,
                        printer
                    )
                );
            case "showOrders":
                return setState(
                    await showOrders(
                        state,
                        action,
                        client,
                        i18n.language,
                        lamp,
                        printer
                    )
                );
            case "loadMoreOrders":
                return setState(
                    await loadMoreOrders(
                        state,
                        action,
                        client,
                        i18n.language,
                        lamp,
                        printer
                    )
                );

            case "showInsights":
                return setState(
                    await showInsights(
                        state,
                        action,
                        client,
                        i18n.language,
                        lamp,
                        printer
                    )
                );

            case "showHardware":
                return setState(
                    await showHardware(
                        state,
                        action,
                        client,
                        i18n.language,
                        lamp,
                        printer
                    )
                );

            case "showProducts": {
                const scanState = requireState(state, "scan");

                setState({
                    ...scanState,
                    subState: {
                        kind: "productPicker",
                        productPreset: action.productPreset
                    }
                });
                tracker.track("page_view", { title: "fixtasten" });
                return;
            }

            case "hideProducts": {
                const scanState = requireState(state, "scan");

                setState({
                    ...scanState,
                    subState: { kind: "idle" }
                });
                tracker.track("page_view", { title: "scan" });
                return;
            }

            case "showKitchenMenu": {
                const scanState = requireState(state, "scan");

                setState({
                    ...scanState,
                    kind: "kitchen",
                    menu: action.kitchenMenu,
                    subState: { kind: "idle" }
                });
                tracker.track("page_view", { title: "kitchen" });
                return;
            }

            case "hideKitchenMenu": {
                const kitchenState = requireState(state, "kitchen");

                setState({
                    ...kitchenState,
                    kind: "scan",
                    subState: { kind: "idle" }
                });
                tracker.track("page_view", { title: "kitchen" });
                return;
            }

            case "startPayment": {
                const scanState = requireState(state, "scan");

                setColor("Brand");
                setState({
                    ...scanState,
                    subState: { kind: "checkingOut" }
                });
                try {
                    const totalPrice = `${scanState.cart.totalPrice.currency} ${scanState.cart.totalPrice.value}`;
                    tracker.track("begin_checkout", { totalPrice });
                    const { orderId, userId, displayId, kitchenOrderId } =
                        await cartClient.checkout(
                            i18n.language,
                            scanState.simulatePaymentResponse
                        );
                    setState({
                        ...state,
                        orderId,
                        userId,
                        displayId,
                        kitchenOrderId,
                        kind: "checkoutSuccess",
                        subState: { kind: "idle" }
                    });

                    if (kitchenOrderId) {
                        dispatch({
                            kind: "printReceipt",
                            orderId,
                            kitchenOrderId
                        });
                    }

                    setColor("Green");
                    tracker.track("checkout_success", { totalPrice });
                    tracker.track("page_view", { title: "end" });
                    return;
                } catch (e) {
                    await logClient.info(
                        `${getEzioId()} checkout failure ${e}`
                    );
                    const error = e as CheckoutError;
                    tracker.track("checkout_failure", {
                        reason: error?.case
                    });

                    if (error.case === "Aborted") return;

                    setColor("Red");

                    setState({
                        ...scanState,
                        subState: { kind: "checkoutError", reason: error }
                    });
                    return;
                }
            }

            case "changeLanguage": {
                await i18n.changeLanguage(action.code);
                tracker.setTrackingProperties({ language: action.code });
                tracker.track("select_content", { language: action.code });
                return;
            }

            case "hideProductNotFound": {
                const allowedState = requireOneOfStates(
                    state,
                    "scan",
                    "kitchen"
                );

                setState({
                    ...allowedState,
                    subState: { kind: "idle" }
                });
                return;
            }

            case "sendFeedback": {
                requireState(state, "checkoutSuccess");

                await postCheckoutClient.sendFeedback(action.rate);
                return;
            }

            case "startGetReceipt": {
                const checkoutSuccessState = requireState(
                    state,
                    "checkoutSuccess"
                );

                setState({
                    ...checkoutSuccessState,
                    subState: { kind: "receiptLoading" }
                });
                const receiptResponse = await orderClient.getReceiptUrl(
                    action.orderId,
                    i18n.language
                );
                setState({
                    ...checkoutSuccessState,
                    subState: {
                        kind: "receiptSuccess",
                        receiptUrl: receiptResponse.receiptUrl
                    }
                });
                tracker.track("receipt_requested");
                return;
            }

            case "hideIdScan": {
                const scanState = requireState(state, "scan");

                setColor("Brand");
                setState({
                    ...scanState,
                    subState: { kind: "idle" }
                });
                return;
            }

            case "finalizeSession": {
                reset();
                return;
            }

            case "reset": {
                void logClient.info("Reset action: Begin");

                // Remote reset
                const shouldAbortPayment =
                    state.kind === "scan" &&
                    state.subState.kind === "checkingOut";
                await postCheckoutClient.reset(
                    shouldAbortPayment,
                    defaultLanguage
                );

                reset();

                void logClient.info("Reset action: End");
                return;
            }

            case "toggleAccessibilityMode": {
                requireOneOfStates(
                    state,
                    "welcome",
                    "scan",
                    "operator",
                    "checkoutSuccess"
                );

                tracker.track("toggle_accessibility_mode", {
                    state: !state.accessibilityMode
                });
                setState({
                    ...state,
                    accessibilityMode: !state.accessibilityMode
                });
                return;
            }
            case "flap": {
                requireState(state, "scan");
                tracker.track("flappy_found");
                setState({
                    ...state,
                    kind: "flappy",
                    subState: { kind: "playing" }
                });
                return;
            }

            case "printReceipt": {
                requireOneOfStates(
                    state,
                    "scan",
                    "checkoutSuccess",
                    "operator"
                );

                if (!import.meta.env.DEV && !connected) {
                    await connect();
                }
                const content = await orderClient.getReceipt(
                    action.orderId,
                    i18n.language,
                    action.kitchenOrderId
                );
                await print(content);

                tracker.track("receipt_printed", {
                    orderId: action.orderId
                });
                return;
            }
            case "showError": {
                return setState({
                    ...state,
                    kind: "error",
                    subState: { kind: "" }
                });
            }
            case "transfer": {
                const { scannedCode } = action;
                if (!isValidTransferCode(scannedCode)) {
                    tracker.track("cart_transfer_failure", {
                        reason: "invalidTransferCode"
                    });
                    return setState({
                        kind: "welcome",
                        subState: {
                            kind: "error",
                            reason: { case: "MalformedTransferCode" }
                        },
                        accessibilityMode: state.accessibilityMode,
                        messages: state.messages
                    });
                }

                try {
                    const cart = await cartClient.transfer(scannedCode.code);
                    tracker.setTrackingProperties({
                        cartId: cart.id,
                        language: i18n.language
                    });
                    tracker.track("cart_transfer_success");
                    return setState({
                        kind: "scan",
                        subState: { kind: "idle" },
                        cart,
                        accessibilityMode: state.accessibilityMode,
                        messages: state.messages
                    });
                } catch (e) {
                    const error = e as CartTransferError;
                    tracker.track("cart_transfer_failure", {
                        reason: error.case
                    });
                    return setState({
                        kind: "welcome",
                        subState: {
                            kind: "error",
                            reason: error
                        },
                        accessibilityMode: state.accessibilityMode,
                        messages: state.messages
                    });
                }
            }
            case "togglePaymentSimulator": {
                const scanState = requireState(state, "scan");

                const { simulatePaymentResponse } = scanState;
                const newValue =
                    simulatePaymentResponse === "failure"
                        ? "success"
                        : "failure";

                return setState({
                    ...scanState,
                    simulatePaymentResponse: newValue
                });
            }

            case "showMessage": {
                const newId = self.crypto.randomUUID();

                setTimeout(() => {
                    setState((prev) => ({
                        ...prev,
                        messages: prev.messages.filter(({ id }) => id !== newId)
                    }));
                }, action.message.timeoutMs);

                const newState: RootState = {
                    ...state,
                    messages: state.messages.concat([
                        { ...action.message, id: newId }
                    ])
                };

                return setState({ ...newState });
            }

            default:
                return action satisfies never;
        }
    };

    const dispatch = actionHandler(client);

    return (
        <StateContext.Provider value={{ dispatch, state }}>
            {state.accessibilityMode && (
                <div className="h-2/6 flex-none bg-black"></div>
            )}
            {children}

            {accessibilityModeAvailable && (
                <div className="flex justify-center">
                    <div
                        className="press-effect flex h-11 w-24 items-center justify-center rounded-tl-full rounded-tr-full bg-primary-100 text-center"
                        onClick={() =>
                            dispatch({
                                kind: "toggleAccessibilityMode"
                            })
                        }
                    >
                        <div className="text-primary-500 [&>*]:w-48">
                            {state.accessibilityMode
                                ? svg.expand
                                : svg.wheelchair}
                        </div>
                    </div>
                </div>
            )}
        </StateContext.Provider>
    );
};
