import { useTranslation } from "react-i18next";

import kitchenMenuLogo from "../../../assets/kitchen-menu.svg";
import { ProductPreset } from "../../types/ProductPreset";
import { translate } from "../../utils/translations";
import { useConfig } from "../ConfigProvider";
import { useAnyState } from "../state/StateProvider";

export const ProductAccessRow = () => {
    const { productPresets, kitchen } = useConfig();
    const { dispatch } = useAnyState();

    const { i18n } = useTranslation();

    const isQuickAccess = (productPreset: ProductPreset) =>
        productPreset.categories.length === 1 &&
        productPreset.categories[0].products.length === 1;

    if (productPresets.length === 0 && !kitchen.hasKitchen) {
        return null;
    } else {
        return (
            <div
                className="flex gap-2"
                data-testid="productAccessRow"
                role="list"
            >
                {productPresets.map((productPreset) => {
                    return isQuickAccess(productPreset) ? (
                        <ProductAccessRowButton
                            key={productPreset.categories[0].products[0].ean}
                            testId={productPreset.categories[0].products[0].ean}
                            label={translate(
                                productPreset.categories[0].products[0]
                                    .translations,
                                i18n
                            )}
                            image={
                                productPreset.categories[0].products[0]
                                    .imageUrl ?? ""
                            }
                            onClick={() =>
                                dispatch({
                                    kind: "addProduct",
                                    payload: {
                                        kind: "manuallyAdded",
                                        code: productPreset.categories[0]
                                            .products[0].ean,
                                        quantity: 1,
                                        analyticsSource: "quickAccess"
                                    }
                                })
                            }
                        />
                    ) : (
                        <ProductAccessRowButton
                            key={productPreset.id}
                            testId={productPreset.name}
                            label={translate(productPreset.translations, i18n)}
                            image={productPreset.imageUrl ?? ""}
                            onClick={() =>
                                dispatch({
                                    kind: "showProducts",
                                    productPreset
                                })
                            }
                        />
                    );
                })}
                {kitchen.hasKitchen && (
                    <ProductAccessRowButton
                        key={"kitchenMenu"}
                        testId={"kitchenMenu"}
                        label=""
                        image={kitchenMenuLogo}
                        onClick={() => {
                            if (kitchen.menu) {
                                dispatch({
                                    kind: "showKitchenMenu",
                                    kitchenMenu: kitchen.menu
                                });
                            }
                        }}
                        disabled={!kitchen.menu}
                        className="!border-[#025F69] !bg-[#025F69]"
                    />
                )}
            </div>
        );
    }
};

const ProductAccessRowButton = ({
    testId,
    label,
    image,
    onClick,
    disabled,
    className
}: {
    testId: string;
    label: string;
    image: string;
    onClick: () => void;
    disabled?: boolean;
    className?: string;
}) => {
    return (
        <button
            role="listitem"
            data-testid={testId}
            onClick={onClick}
            disabled={disabled}
            className={`
            press-effect
            flex-1
            place-items-center
            rounded
            border-2
            border-primary-200
            bg-primary-100
            text-center text-xs font-bold
            text-primary-800
            disabled:opacity-30
            ${className}
        `}
        >
            <img src={image} className="mx-auto h-16 w-auto" />
            {label}
        </button>
    );
};
