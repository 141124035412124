export function splitArray<T>(
    arr: T[],
    predicate: (item: T) => boolean
): T[][] {
    const result: T[][] = [];
    let current: T[] = [];

    for (const item of arr) {
        if (predicate(item)) {
            result.push(current);
            current = [];
        } else {
            current.push(item);
        }
    }

    result.push(current);
    return result;
}
