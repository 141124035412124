import { PropsWithChildren } from "react";

import { useAnyState } from "./state/StateProvider";

export function MessageHandler({ children }: PropsWithChildren) {
    const { state } = useAnyState();

    return (
        <>
            <div className="absolute bottom-24 left-0 right-0 z-30 me-auto ms-auto flex w-fit flex-col gap-6">
                {state.messages.map(({ content, id }) => (
                    <div key={id}>{content}</div>
                ))}
            </div>
            {children}
        </>
    );
}
